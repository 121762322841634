import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import withMouseStates from '@redoute/with-mouse-states';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Component = makeShortcode("Component");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "withmousestates"
    }}>{`withMouseStates`}</h1>
    <p>{`The withMouseStates is a HOC. You can use it to add mouse states on a button.
It's very useful if you can to change the aspect of an icon when hovered or active.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`WrappedComponent`}</inlineCode>{` will get the props `}<inlineCode parentName="p">{`isHovered`}</inlineCode>{` and `}<inlineCode parentName="p">{`isActive`}</inlineCode>{`.
Your component can also take a `}<inlineCode parentName="p">{`isDisabled`}</inlineCode>{` props to prevent any the mouse event.`}</p>
    <Playground __position={0} __code={'() => {\n  const [disabled, setDisabled] = useState(false)\n  const Component = withMouseStates(props => {\n    return (\n      <div style={{ border: \'1px solid #000\' }}>\n        <p>Is hovered: {props.isHovered ? \'yes\' : \'no\'}</p>\n        <p>Is active: {props.isActive ? \'yes\' : \'no\'}</p>\n        <p>Is Disabled: {props.isDisabled ? \'yes\' : \'no\'}</p>\n      </div>\n    )\n  })\n  return (\n    <>\n      <div onClick={() => setDisabled(state => !state)}>\n        click to {!disabled ? \'disable\' : \'enable\'}\n      </div>\n      <Component isDisabled={disabled} />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      withMouseStates,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [disabled, setDisabled] = useState(false);
        const Component = withMouseStates(props => {
          return <div style={{
            border: '1px solid #000'
          }}>
        <p>
          Is hovered: {props.isHovered ? 'yes' : 'no'}
        </p>
        <p>
          Is active: {props.isActive ? 'yes' : 'no'}
        </p>
        <p>
          Is Disabled: {props.isDisabled ? 'yes' : 'no'}
        </p>
      </div>;
        });
        return <>
      <div onClick={() => setDisabled(state => !state)}>
          click to {!disabled ? 'disable' : 'enable'}
      </div>
      <Component isDisabled={disabled} mdxType="Component" />
      </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      